<template>
  <v-row class="fill-height">
    <v-col>
      <v-card>
        <v-card-title>
          <v-icon>mdi-hair-dryer-outline</v-icon>
          Lista de profissionais
          <v-spacer></v-spacer>
          <v-text-field
            class="mr-3"
            append-icon="mdi-magnify"
            label="Busca"
            single-line
            hide-details
            @keydown.enter="buscar()"
            @click:append="buscar()"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn outlined :to="'/bmsCreate'">
            <v-icon left>mdi-plus-thick</v-icon>
            Novo Profissional
          </v-btn>
        </v-card-title>
        <v-alert type="warning" outlined v-show="!bms"
          >Nenhum usuário encontrado.</v-alert
        >
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="bms"
          item-key="id"
          class="elevation-1"
          v-show="bms"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :to="{
                    name: 'bm',
                    params: { id: item.id }
                  }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { mask } from "vue-the-mask";

import {
  BMS_GET
  // BM_EDIT,
  // BM_CREATE,
  // BM_SEARCH,
} from "@/store/actions/bm.type";
import { BM_RESET_STATE } from "@/store/mutations/bm.type";

export default {
  name: "ListarUsuarios",
  directives: { mask },
  props: {
    source: String
  },

  components: {},
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.dispatch(BM_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([store.dispatch(BM_RESET_STATE), store.dispatch(BMS_GET)]).then(
      () => {
        next();
      }
    );
  },
  data: () => ({
    //Lista de Usuários
    pagination: {
      sortBy: "name"
    },
    search: "",
    selected: [],
    headers: [
      {
        text: "Nome",
        align: "left",
        value: "name"
      },
      // { text: "Instituição", value: "instituicao" },
      { text: "Document", value: "document" },
      { text: "obs", value: "obs" },
      { text: "Editar", value: "actions", sortable: false }
    ],

    formIsValid: false,

    textRules: [
      v => !!v || "Este campo é obrigatório"
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ],
    cpfRule: [
      v => !!v || "CPF é obrigatório",
      strCPF => {
        if (!strCPF) return false;
        strCPF = strCPF.replace(/\D/g, "");
        let Soma;
        let Resto;
        Soma = 0;
        if (strCPF === "00000000000") return "CPF Inválido";
        let i = 0;
        for (i = 1; i <= 9; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if (Resto === 10 || Resto === 11) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10))) return "CPF Inválido";

        Soma = 0;
        for (i = 1; i <= 10; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if (Resto === 10 || Resto === 11) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11))) return "CPF Inválido";
        return true;
      }
    ],
    emailRules: [
      v => !!v || "E-mail é obrigatório",
      value => {
        if (typeof value !== "undefined" && value.length > 0) {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Informe um email válido";
        }
        return false;
      }
    ]
  }),
  methods: {
    //Methods Lista Usuarios
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.dados.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    buscar() {
      // store.dispatch(USER_SEARCH);
      // console.log("buscar");
    },
    //Fim Methods Lista Usuarios

    reset() {
      this.$refs.formUsuario.reset();
    }

    // usuarioSubmit(id) {
    //   if (this.$refs.formUsuario.validate()) {
    //     //this.subject.image = this.subject.image
    //
    //     let action = id ? USER_EDIT : USER_CREATE;
    //     this.inProgress = true;
    //     this.bloqueiaEnvio = true;
    //     this.$store
    //       .dispatch(action)
    //       .then(() => {
    //         this.inProgress = false;
    //         this.bloqueiaEnvio = false;
    //         // this.$router.push({
    //         //   name: "usuario",
    //         //   params: { id: data.id }
    //         // });
    //         this.$refs.formUsuario.reset();
    //         this.snackbar = true;
    //         this.color = "success";
    //         this.text = "Salvo com sucesso";
    //       })
    //       .catch(({ response }) => {
    //         this.inProgress = false;
    //         this.bloqueiaEnvio = false;
    //         this.snackbar = true;
    //         this.color = "error";
    //         this.text = "Erro ao salvar usuário.";
    //         // console.log(response.data);
    //         if (typeof response.data.error !== "undefined") {
    //           this.text = response.data.error;
    //         }
    //       });
    //   } else {
    //     this.snackbar = true;
    //     this.color = "error";
    //     this.text = "Preencha o formulário corretamente";
    //   }
    // },
  },
  mounted() {},
  computed: {
    // ...mapState({
    //   courses: state => state.
    // })

    ...mapGetters(["bms", "checkUsers"])
  },
  watch: {}
};
</script>
